import React from 'react'
import './Portfolio.css'
import '../../index.css'
import Proj1 from '../../Assets/Proj1.gif'
// import Proj2 from '../../Assets/Proj2.jpg'
import Proj3 from '../../Assets/Proj3.png'
import Proj4 from '../../Assets/Proj4.jpg'
import Proj5 from '../../Assets/Proj5.png'
import Proj6 from '../../Assets/Proj6.jpg'



const data = [
  {
    id: 1,
    image: Proj1,
    title: 'Unicorn-Uber, used AWS services to make project for hands-on experience',
    github: 'https://github.com/Dacrron/Unicorn-Uber',
    demo: 'https://master.d1hnkutg2u0uye.amplifyapp.com/'
  },
  {
    id: 2,
    image: Proj6,
    title: 'CyberSec Blogs, Started writing about cybersecurity topics',
    github: 'https://substack.com/@dacr0n?utm_source=profile-page',
    demo: 'https://substack.com/@dacr0n?utm_source=profile-page'
  },
  {
    id: 3,
    image: Proj5,
    title: 'Backend BookStore(Bookie), ASP.net and SSMS ',
    github: 'https://github.com/Dacrron/BookStore_Management_Backend',
    demo: 'https://github.com/Dacrron/BookStore_Management_Backend'
  },
  {
    id: 4,
    image: Proj4,
    title: 'Portfolio website using React and NodeJs',
    github: 'https://github.com/Dacrron/Portfolio',
    demo: 'https://profile.chinmay.link/'
  },
  {
    id: 5,
    image: Proj3,
    title: 'Textify, Use of Python Tkinter for a graphical user interface and AWS Textract and Polly for Text manipulation',
    github: 'https://github.com/Dacrron/Textify-AWS',
    demo: 'https://github.com/Dacrron/Textify-AWS'
  },
]



const Portfolio = () => {
  return (
    <section id='portfolio' >
      <h5>My Recent Work</h5>
      <h2>Portfolio</h2>

      <div className="container portfolio__container">
        {
          data.map(({id, image, title, github, demo}) =>{
            return(
              <article key={id} className='portfolio__item'>
          <div className="portfolio__item-image">
            <img src={image} alt="" />
          </div>
          <h3>{title}</h3>
          <div className="portfolio__item-cta"></div>
          <div className="portfolio__item-cta">
          <a href={github} className='btn' target='_blank'>Github</a>
          <a href={demo} className='btn btn-primary' target='_blank'>Live Demo</a>
          </div>
          </article>
            )
          })
        }
      </div>


    </section>
  )
}

export default Portfolio