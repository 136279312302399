import React from 'react'
import { FaCheck } from "react-icons/fa6";
import './Study.css'

const Study = () => {
  return (
    <section id='services'>
        <h5>What I Know?</h5>
        <h2>My Knowledge</h2>

        <div className="container services__container">
           <article className="service">
            <div className="service__head">
                <h3>Operating Systems</h3>
            </div>

            <ul className='service__list'>
                <li>
                    <FaCheck className='service__list-icon' />
                    <p>Windows 7,10 and 11</p>
                </li>
                <li>
                    <FaCheck className='service__list-icon' />
                    <p>Kali Linux</p>
                </li>
                <li>
                    <FaCheck className='service__list-icon' />
                    <p>Ubuntu</p>
                </li>
                
            </ul>


           </article>
           {/* end */}
           <article className="service">
            <div className="service__head">
                <h3>Learnings</h3>
            </div>

            <ul className='service__list'>
                <li>
                    <FaCheck className='service__list-icon' />
                    <p><b>Analyst Level 1</b> certification on TryHackMe</p>
                </li>
                <li>
                    <FaCheck className='service__list-icon' />
                    <p>Workshop on ASP.net and Microsoft SSMS database</p>
                </li>
                <li>
                    <FaCheck className='service__list-icon' />
                    <p><b>Web Development certification</b> from Udemy</p>
                </li>
                <li>
                    <FaCheck className='service__list-icon' />
                    <p><b>CTF challenges on TryHackme</b></p>
                </li>
                <li>
                    <FaCheck className='service__list-icon' />
                    <p> <b>Basics of Ethical Hacking</b> on Udemy</p>
                </li>
               
            </ul>


           </article>

           
        </div> 
    </section>
  )
}

export default Study