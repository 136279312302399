import React from 'react'
import { SiLinkedin } from "react-icons/si";
import { FaGithub } from "react-icons/fa";
// import { SiTryhackme } from "react-icons/si";

const HeaderSocials = () => {
  return (
    <div className='header__socials'>

        <a href="https//Linkedin.com" target = "_blank">< SiLinkedin /> </a>
        <a href="https//github.com" target = "_blank">< FaGithub /> </a>
        {/* <a href="https//tryhackme.com" target = "_blank">< SiTryhackme /> </a> */}



    </div>
  )
}

export default HeaderSocials