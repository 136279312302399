import React from 'react'
import './Contact.css'
import { MdOutlineEmail } from "react-icons/md";
import { IoLogoDiscord } from "react-icons/io5";
import { FaLinkedin } from "react-icons/fa6";


const Contact = () => {
  return (
    <section id='contact' >
      <h5>Get In Touch</h5>
      <h2>Contact Me</h2>

      <div className="container contact__container">
        <div className="contact__options">         
          <article className="contact__option">
          <a href="mailto:chinmay.hegde2001@gmail.com" target='_blank'  ><IoLogoDiscord /></a>
            <h4>Discord</h4>
            <h5>Dacron</h5>
            <a href="https://discordapp.com/channels/@me/dacroon/" target='_blank'>Send a message through Discord</a>
          </article>
          <article className="contact__option">
          <a href="mailto:chinmay.hegde2001@gmail.com" target='_blank'  ><MdOutlineEmail /></a>
            <h4>Email</h4>
            <h5>chinmay.hegde2001@gmail.com</h5>
            <a href="mailto:chinmay.hegde2001@gmail.com" target='_blank'>Send a message through Email</a>
          </article>
          <article className="contact__option">
          <a href="mailto:chinmay.hegde2001@gmail.com" target='_blank'  ><FaLinkedin /></a>
            <h4>Linkedin</h4>
            <h5>Chinmay Hegde</h5>
            <a href="https://www.linkedin.com/in/chinmay-hegde-401076218/" target='_blank'>Send a message through Linkedin</a>
          </article>
        </div>
      </div>

    </section>
  )
}

export default Contact