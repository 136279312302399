import React from 'react'
import './About.css'
import ME from '../../Assets/Passport.png'
import { FaAward } from "react-icons/fa6";
import { TbCertificate } from "react-icons/tb";
import { LuBriefcase } from "react-icons/lu";


const About = () => {
  return (
    <section id='about'>
      <h5>Get To Know</h5>
      <h2>About Me</h2>

      <div className="container about__container">
        <div className="about__me">
          <div className="about__me-image">
            <img src={ME} alt="About"  />
          </div>

        </div>
        <div className="about__content">
          <div className="about__cards">
            <article className='about__card'>
              < FaAward className='about__icon'/>
              <h5>IT Level</h5>
              <small>Fresher</small>
            </article>
            <article className='about__card'>
              < TbCertificate className='about__icon'/>
              <h5>Certificate</h5>
              <small>10+ Certificate</small>
            </article><article className='about__card'>
              < LuBriefcase className='about__icon'/>
              <h5>Projects</h5>
              <small>3+ Completed</small>
            </article>
          </div>
          <p>
          I am Chinmay Hegde, actively seeking to enter into IT industry. I am confident my expertise in security analysis, AWS services, and web development will contribute to my professional growth within the company. Additionally, I am active on TryHackMe, where I have completed various rooms and Capture The Flag (CTF) challenges, enhancing my skills in the field.
          </p>

          <a href="#contact" className='btn btn-primary'>Hire Me</a>


        </div>
      </div>
      
    </section>
  )
}

export default About