import React from 'react'
import CV from '../../Assets/Chinmay_Resume_2024__.pdf'


const CTA = () => {
  return (
    <div className = 'cta'> 
        <a href={CV} download className='btn'>Download Resume</a> 
        <a href="#contact" className='btn btn-primary'>Contact Me</a>   
    
    
    </div>
  )
}

export default CTA