import React from 'react'
import './footer.css'
import { IoLogoDiscord } from "react-icons/io5";
import { FaLinkedin } from "react-icons/fa6";
import { AiOutlineGithub } from "react-icons/ai";


const Footer = () => {
  return (
    <footer id='footer'>
      <a href="#" className='footerlogo'>Chinmay Hegde</a>

      <ul className="permalinks">
        <li><a href="#">Home</a></li>
        <li><a href="#about">About</a></li>
        <li><a href="#experience">Experience</a></li>
        <li><a href="#services">What I Know?</a></li>
        <li><a href="#portfolio">Portfolio</a></li>
        <li><a href="#contact">Contact</a></li>
      </ul>

      <div className="footer__socials">
        <a href="https://https://www.linkedin.com/in/chinmay-hegde-401076218/"><FaLinkedin/></a>
        <a href="https://discordapp.com/channels/@me/dacroon/"><IoLogoDiscord/></a>
        <a href="https://https://github.com/Dacrron"><AiOutlineGithub/></a>
      </div>

      <div className="footer_copyright">
        <small>&copy; Chinmay Hegde</small>
      </div>

    </footer>
  )
}

export default Footer