import React from 'react'
import Header from './Component/header/header'
import Nav from './Component/nav/nav'
import About from './Component/about/About'
import Experience from './Component/Experience/Experience'
import Portfolio from './Component/Portfolio/Portfolio'
import Contact from './Component/Contact/Contact'
import Footer from './Component/footer/footer'
import Study from './Component/Studies/Study'



const app = () => {
  return (
    <>
      <Header /> 
      <Nav />
      <About />
      <Experience />
      <Study/>
      <Portfolio />
      <Contact />
      <Footer />
    
    </>
    
    
  )
}

export default app